import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import CookieConsent from "react-cookie-consent";
import { ContentWrapper } from '../components/common';
import Seo from '../components/seo';
import ContactModal from '../components/ContactModal';
import theme from '../theme';
import bgSrc from '../images/img-bg.jpg';
import logoSrc from '../images/logo-hutisko.png';
import logoProject from '../images/logo-vitez-realitni-projekt-roku.png';
import cloudSrc01 from '../images/anim01.png';
import cloudSrc02 from '../images/anim02.png';
import MapSvg from '../images/svg/address.svg';
import CallSvg from '../images/svg/call.svg';
import MailSvg from '../images/svg/mail.svg';
import { PRIVACY_POLICY } from '../routes';
import { RECAPTCHA_SITE_KEY} from '../config';

const ConsentContent = styled.div`
  a {
    color: ${({theme}) => theme.colors.text_primary};
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
`;

const MapIcon = styled(MapSvg)`
  width: 40px;
  height: auto;
  fill: ${({theme}) => theme.colors.primary};

  @media (min-width: 768px) {
    fill: ${({theme}) => theme.colors.white};
  }
`;

const CallIcon = styled(CallSvg)`
  width: 40px;
  height: auto;
  fill: ${({theme}) => theme.colors.primary};

  @media (min-width: 768px) {
    fill: ${({theme}) => theme.colors.white};
  }
`;

const MailIcon = styled(MailSvg)`
  width: 40px;
  height: auto;
  fill: ${({theme}) => theme.colors.primary};

  @media (min-width: 768px) {
    fill: ${({theme}) => theme.colors.white};
  }
`;

const Background = styled.div`
  position: fixed;
  background-color: ${({theme}) => theme.colors.secondary};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .background {
    display: none;
  }

  @media (min-width: 768px) {

    background-color: ${({theme}) => theme.colors.white};
    background-image: url(${bgSrc});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .background {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 3rem .25rem;
`;

const LogoWrapper = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
  margin-top: 2rem;
  margin-bottom: 3rem;

  img {
    width: 100%;
    height: auto;
    max-width: 240px;
  }

  @media (min-width: 768px) {
    img {
      max-width: 300px;
    }
  }

  h1 {
    display: none;
  }
`;

const LogoProjectWrapper = styled.div`
  text-align: center;
  z-index: 1;
  margin-bottom: 3rem;

  img {
    width: 100%;
    height: auto;
    max-width: 152px;
    border-radius: 5px;
    padding: 1rem;
    background-color: rgba(255,255,255,0.5);
  }

  @media (min-width: 768px) {
    position: absolute;
    right: 1rem;
    top: 1rem;

    img {
      max-width: 152px;
    }
  }
`;

const Content = styled.div`
  z-index: 1;
  text-align: center;
`;

const LargeText = styled.p`
  font-size: 2.5rem;
  font-family: 'Bellefair', Georgia, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.875rem;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`;

// const SaleInfo = styled(Text)`
//   font-size: 1.5rem;
//   margin-bottom: 1rem;

//   strong {
//     color: ${({theme}) => theme.colors.primary};
//   }

//   @media (min-width: 768px) {
//     strong {
//       color: ${({theme}) => theme.colors.white};
//     }
//   }
// `;

const CustomBtn = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 3rem;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #fff;
  background-color: ${({theme}) => theme.colors.primary};
  border: 0;
  font-weight: 400;
  padding: 0.75rem 2.25rem;
  transition: all .3s ease;
  border-radius: 8px;
  border: 2px solid ${({theme}) => theme.colors.primary};

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    border: 2px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.transparent};
  }

  @media (min-width: 768px) {
    background-color: ${({theme}) => theme.colors.secondary};
    border: 2px solid ${({theme}) => theme.colors.secondary};

    &:hover {
      text-decoration: none;
      color: ${({theme}) => theme.colors.white};
      border: 2px solid ${({theme}) => theme.colors.primary};
      background-color: ${({theme}) => theme.colors.primary};
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  p {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  a, a:hover {
    color: #fff;
    text-decoration: none
  }
`;

const Info = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  margin-top: 3rem;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 4.5rem;
  }
`;

const Footer = styled.div`
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;

  a {
    text-decoration: underline;
    color: #fff;
  }

  p {
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const VideoContent = styled.div`
  margin-bottom: 2rem;
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 4.5rem;
  }
`;

const VideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function Index () {
  const [isOpen, setOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
        <ContactModal
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
       <CookieConsent
          location="bottom"
          buttonText="Rozumím"
          cookieName="hu-cookie-consent"
          style={{ background: "#fff", color: "#666666", fontSize: "0.875rem" }}
          buttonStyle={{ backgroundColor: "#caad72", color: "#fff", borderRadius: "5px", fontSize: "1rem", padding: "0.5rem 1rem" }}
          expires={150}
        >
          <ConsentContent>
            Tento web používá k&nbsp;poskytování služeb, personalizaci reklam a&nbsp;analýze návštěvnosti soubory cookie. Používáním tohoto webu s&nbsp;tím souhlasíte. <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer" title="Zobrazit soubor - Informace o zpracování osobních údajů">Ochrana osobních údajů</a>
          </ConsentContent>
        </CookieConsent>
      <Background>
        <div className="background">
          <div className="layer background-image"></div>

          <div className="layer clouds">
            <div className="cloud cloud-1">
              <img src={cloudSrc01} width="1600" height="850" alt="Animace" />
            </div>

            <div className="cloud cloud-2">
              <img src={cloudSrc02}  width="1600" height="850" alt="Animace" />
            </div>

            <div className="cloud cloud-3">
              <img src={cloudSrc01} width="1600" height="850" alt="Animace" />
            </div>
          </div>
        </div>
      </Background>
      <Wrapper>
        <Seo />

        <LogoWrapper>
          <img src={logoSrc} alt="Logo - Apartmány Hutisko" />
          <h1>Apartmány Hutisko - vlastní domov v&nbsp;přírodě</h1>
        </LogoWrapper>
        <LogoProjectWrapper>
          <img src={logoProject} alt="Realitní projekt roku 2021 - Zlínský kraj" />
        </LogoProjectWrapper>

        <Content>
          <ContentWrapper>
            <VideoContent>
              <VideoWrapper>
                <iframe width="560" height="315" src={`https://www.youtube.com/embed/_9AyOsppql0`} title="Hutisko - apartmány na prodej" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </VideoWrapper>
            </VideoContent>

            <LargeText>Již brzy</LargeText>
            {/* <SaleInfo>Spuštění prodeje <strong>podzim&nbsp;2021</strong></SaleInfo> */}
            <Text>Připravujeme pro vás 20&nbsp;bytů se soukromým wellness v&nbsp;krásném prostředí Beskyd a&nbsp;Javorníků.</Text>

            <CustomBtn onClick={() => setOpen(true)}>
              Chci vědět více
            </CustomBtn>

            <Info>
              <Item>
                <IconWrapper>
                  <CallIcon />
                </IconWrapper>
                <p>+420&nbsp;777&nbsp;775&nbsp;058</p>
              </Item>
              <Item>
                <IconWrapper>
                  <MailIcon />
                </IconWrapper>
                <p>info@hutisko.cz</p>
              </Item>
              <Item>
                <IconWrapper>
                  <MapIcon />
                </IconWrapper>
                <p>Hutisko-Solanec 245</p>
                <p>756&nbsp;62 Hutisko-Solanec</p>
              </Item>
            </Info>

            <Footer>
              <div>
                <p><a href={PRIVACY_POLICY} target="_blank" rel="noreferrer" title="Zobrazit soubor - Informace o zpracování osobních údajů">Ochrana osobních údajů</a></p>
                <p>&copy; 2022&nbsp;Všechna práva vyhrazena hutisko.cz</p>
              </div>
              <p>Vytvořil&nbsp;<a href="https://realitni-marketing.cz/" target="_blank" rel="noreferrer">Realitní&nbsp;marketing</a></p>
            </Footer>
          </ContentWrapper>
        </Content>
      </Wrapper>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  )
}

export default Index;
