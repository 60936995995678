import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setIn } from 'final-form';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import { MESSAGE_URL, } from '../../config';

const validationSchema = yup.object({
  name: yup.string().nullable(true).required('Pole je povinné'),
	email: yup.string().nullable(true).required('Pole je povinné').email('Email musí být ve správném tvaru'),
  message: yup.string().nullable(true).required('Pole je povinné').max(1500, 'Je povoleno vložit max. 1500 znaků'),
});

const validateFormValues = (schema) => async (values) => {
	if (typeof schema === 'function') {
		schema = schema();
	}
	try {
		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
		}, {});

		return errors;
	}
};

const validate = validateFormValues(validationSchema);

export const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(10,10,10,.30)',
    zIndex: 100,
  },
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    border: '0',
    boxShadow: '0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)',
    color: '#303030',
    width: '90%',
    maxWidth: '600px',
    maxHeight: '90%',
    padding: '20px 0',
  }
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding: 0 1rem 1rem;

  span {
    font-weight: bold;
  }

  svg {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  padding: 1rem 1rem 1rem;


  @media(min-width: 768px) {
    padding: 1rem 3rem 1rem;
  }
`;

const Input = styled.input`
  height: 50px;
  padding: 0 0.75rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({theme, hasError}) => hasError ? theme.colors.error : '#bdbdbd'};

  &:focus {
    outline: 0;
    padding: 0 0.75rem;
    border: 1px solid ${({theme}) => theme.colors.secondary};
  }

  @media(min-width: 992px) {
    height: 60px;
    padding: 0 0.75rem;
  }
`;

const TextArea = styled.textarea`

  width: 100%;
  color: ${({theme}) => theme.colors.primary_text};
  padding: 0.75rem;
  min-height: 9.375rem;
  background-color: ${({theme}) => theme.colors.transparent};
  border: 1px solid ${({theme, hasError}) => hasError ? theme.colors.error : '#bdbdbd'};
  outline: 0;
  border-radius: 8px;

  &:focus {
    border: 1px solid ${({theme}) => theme.colors.secondary};
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.error};
  padding-left: 0.75rem;
  margin-bottom: 0;
`;

const SubmitError = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.error};
  margin-bottom: 0;
  grid-column: span 2;
`;


const TextareaWrapper = styled.div`
  input {
    width: 100%;
  }

  @media (min-width: 992px) {
    grid-column: span 2;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 2rem;
  text-align: center;

  button {
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #fff;
    background-color: ${({theme}) => theme.colors.secondary};
    border: 0;
    font-weight: 400;
    padding: 0.75rem 2.25rem;
    transition: all .3s ease;
    border-radius: 8px;
    width: 100%;

    &:hover {
      text-decoration: none;
      color: ${({theme}) => theme.colors.white};
      background-color: ${({theme}) => theme.colors.primary};
    }

    &:disabled {
      background-color: #c1c1c1;
      color: ${({theme}) => theme.colors.white};
      cursor: not-allowed;
    }

    &:focus {
      border: 0;
      outline: 0;
    }
  }

  @media(min-width: 576px) {
    text-align: right;

    button {
      width: auto;
      min-width: 180px;
    }
  }

  @media (min-width: 992px) {
    grid-column: span 2;
  }
`;

const CloseIcon = styled.span`
  cursor: pointer;
  display: block;
  line-height: 1.5rem;
  font-size: 1.5rem;
`;

Modal.setAppElement('#___gatsby')

function ContactModal({
  isOpen,
  onClose,
}) {

  const [showError, setShowError] = useState(false);
  const [isSent, setSent] = useState(false);
  const { executeRecaptcha} = useGoogleReCaptcha();

  const onSubmit = async (values, form) => {
    const token = await executeRecaptcha("contact_form");

    setShowError(false);
    setSent(false);
    axios.post(`${MESSAGE_URL}/message.php`, {
      ...values,
      token
    })
    .then(response => {
      setShowError(false);
      setSent(true);
      form.restart();
    })
    .catch(error => {
      setShowError(true);
      setSent(false);
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={customStyles}
      contentLabel=""
      onAfterOpen={() => {
        setShowError(false);
        setSent(false);
      }}
    >
    <ModalHeader>
      <span>Kontaktujte nás</span>
      <CloseIcon onClick={onClose}>x</CloseIcon>
    </ModalHeader>
    <ModalContent>
      <p>Vyplňte kontaktní formulář a&nbsp;my se vám obratem ozveme.</p>
      <Form
    onSubmit={(values, form) => onSubmit(values, form)}
    initialValues={{
      name: '',
      email: '',
      phone: '',
    }}
    validate={validate}
    render={({ handleSubmit, hasValidationErrors, submitting}) => (
      <>
       <Field
          name="name"
          type="text"
        >
          {({ input, meta }) => (
            <InputWrapper>
              <Input {...input} hasError={meta.error && meta.touched} placeholder="Jméno a příjmení *" />
              {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
            </InputWrapper>
          )}
        </Field>
        <Field
          name="email"
          type="text"
        >
          {({ input, meta }) => (
            <InputWrapper>
              <Input {...input} hasError={meta.error && meta.touched} placeholder="Email *" />
              {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
            </InputWrapper>
          )}
        </Field>
    <TextareaWrapper>
      <Field
        name="message"
        type="text"
      >
        {({ input, meta }) => (
          <InputWrapper>
            <TextArea {...input} hasError={meta.error && meta.touched} placeholder="Zpráva: *" />
            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
          </InputWrapper>
        )}
      </Field>
    </TextareaWrapper>
      { showError && <SubmitError>Při odesílání zprávy došlo k&nbsp;chybě!</SubmitError> }
      <ButtonWrapper>
        <button onClick={handleSubmit} disabled={hasValidationErrors || submitting || isSent}>{isSent ? 'Odesláno' : 'Odeslat'}</button>
      </ButtonWrapper>
      </>
      )}
    />

     </ModalContent>
  </Modal>
  );
}

export default ContactModal;